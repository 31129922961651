import React, { useState, useRef, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import './Header.css'
import logo from '../../assets/header/logo.svg'
import arrowUpRed from '../../assets/header/arrow-up-red.svg'
import arrowDownRed from '../../assets/header/arrow-down-red.svg'
import arrowDownBlue from '../../assets/header/arrow-down-blue.svg'
import arrowUpBlue from '../../assets/header/arrow-up-blue.svg'
import burgerIcon from '../../assets/header/burger-icon.svg'
import closeIcon from '../../assets/header/close-icon.svg'
import RedBtn from '../../components/ui/redBtn/RedBtn'

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false) // State to manage menu visibility on small screens
  const dropdownTimeoutRef = useRef(null)
  const headerRef = useRef(null)
  const [isUpArrowRed, setIsUpArrowRed] = useState(false)

  const location = useLocation()
  const [activeNavItem, setActiveNavItem] = useState('/')
  useEffect(() => {
    setActiveNavItem(location.pathname)
  }, [location])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen) // Toggle the menu visibility
  }

  const handleScroll = id => {
    const anchor = document.getElementById(id)
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleMouseEnter = () => {
    if (dropdownTimeoutRef.current) {
      clearTimeout(dropdownTimeoutRef.current)
    }
    setDropdownOpen(true)
    setIsUpArrowRed(true)
  }

  const handleMouseLeave = () => {
    dropdownTimeoutRef.current = setTimeout(() => {
      setDropdownOpen(false)
    }, 200) // Adjust the delay as needed
  }

  const handleClickOutside = event => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setDropdownOpen(false)
    }
  }

  const handleNavLinkClick = () => {
    setMenuOpen(false)
    setDropdownOpen(false)
  }

  const handleDropdownClick = () => {
    // setActiveNavItem('/other');
    toggleDropdown()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <header ref={headerRef} className='header-container container'>
      <nav className='nav'>
        <Link to='/'>
          <img src={logo} alt='logo' className='header-logo-img' />
        </Link>

        <div className='toggle-container'>
          {!menuOpen && <RedBtn btnLabel='Войти' btnLink='/login' />}
          <button className='menu-toggle' onClick={toggleMenu}>
            <img src={menuOpen ? closeIcon : burgerIcon} alt='open and close icons' />
          </button>
        </div>

        <ul className={`nav-list ${menuOpen ? 'show' : ''}`}>
          <li className={`nav-item ${activeNavItem === '/' ? 'active-nav-item' : ''}`} onClick={() => setActiveNavItem('/')}>
            <Link className='nav-link' to='/' onClick={handleNavLinkClick}>
              Главная
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/about-bank' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/about-bank')}>
            <Link className='nav-link' to='/about-bank' onClick={handleNavLinkClick}>
              О Банке
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/tariffs-and-services' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/tariffs-and-services')}>
            <Link className='nav-link' to='/tariffs-and-services' onClick={handleNavLinkClick}>
              Тарифы и<br />
              Услуги
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/corporate-clients' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/corporate-clients')}>
            <Link className='nav-link' to='/corporate-clients' onClick={handleNavLinkClick}>
              Корпоративным
              <br />
              Клиентам
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/physical-clients' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/physical-clients')}>
            <Link className='nav-link' to='/physical-clients' onClick={handleNavLinkClick}>
              Физическим
              <br />
              Клиентам
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/payment-cards' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/payment-cards')}>
            <Link className='nav-link' to='/payment-cards' onClick={handleNavLinkClick}>
              Платежные
              <br />
              Карты
            </Link>
          </li>
          <li
            className={`nav-item ${activeNavItem === '/crypto-operations' ? 'active-nav-item' : ''}`}
            onClick={() => setActiveNavItem('/crypto-operations')}>
            <Link className='nav-link min-content' to='/crypto-operations' onClick={handleNavLinkClick}>
              Операции с Криптовалютой
            </Link>
          </li>

          <li
            className={`nav-item dropdown ${activeNavItem === '/other-services' ? 'active-nav-item' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleDropdownClick}>
            <div className='flex-row'>
              <span className='nav-link'>Другие услуги</span>

              <div className='arrow-container' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {dropdownOpen ? (
                  <img
                    src={activeNavItem === '/other-services' ? arrowUpRed : isUpArrowRed ? arrowUpRed : arrowUpBlue}
                    alt='arrow-up'
                    className='header-arrow'
                  />
                ) : (
                  <img
                    src={activeNavItem === '/other-services' ? arrowDownRed : arrowDownBlue}
                    alt='arrowdown'
                    className='header-arrow'
                  />
                )}
              </div>
            </div>

            {dropdownOpen && (
              <ul className='dropdown-content' onMouseEnter={() => setIsUpArrowRed(false)}>
                <li>
                  <NavLink
                    to='/other-services#deposit-account'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('deposit')
                      handleNavLinkClick()
                    }}>
                    Депозитный счет
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#cashing-checks'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('cashing-checks')
                      handleNavLinkClick()
                    }}>
                    Обналичивание банковских чеков
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#international-acquiring'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('international-acquiring')
                      handleNavLinkClick()
                    }}>
                    Международный эквайринг
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#operations-metals'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('operations-metals')
                      handleNavLinkClick()
                    }}>
                    Операции с драгоценными металлами
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/other-services#sberkassa-fx'
                    className='dropdown-nav-link'
                    onClick={() => {
                      handleScroll('sberkassa-fx')
                      handleNavLinkClick()
                    }}>
                    Международная СберКасса FX
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <RedBtn btnLabel='Войти' btnLink='/login' />
        </ul>
      </nav>
    </header>
  )
}

export default Header
