import React, { useState } from 'react'
import './Home.css'

import { useDispatch } from 'react-redux'
import { sendContactFormThunk } from '../../store/reducers/actions'

import HeroWithBanner from '../../components/hero-with-banner/HeroWithBanner'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import MyInput from '../../components/ui/MyInput/MyInput'
import NewCustomModal from '../../components/ui/newCustomModal/newCustomModal'
import heroBanner from '../../assets/home/hero-banner.png'
import about_bank_today from '../../assets/home/about-bank-today.png'
import advantages_cards from '../../assets/home/advantages-cards-img.webp'

const Home = () => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' })
  const [errors, setErrors] = useState({})
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupType, setPopupType] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const newErrors = {}

    if (!formData.name.trim()) newErrors.name = 'ФИО обязательно для заполнения'
    if (!emailRegex.test(formData.email)) newErrors.email = 'Некорректный адрес электронной почты'
    if (!formData.phone.trim()) newErrors.phone = 'Номер телефона обязателен для заполнения'

    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validateForm()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    setIsLoading(true)
    try {
      await dispatch(sendContactFormThunk(formData))
      setPopupType('confirm')
      setIsPopupOpen(true)
      setFormData({ name: '', email: '', phone: '' })
    } catch (error) {
      setPopupType('error')
      setIsPopupOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const heroData = {
    heroImage: heroBanner,
    title: 'Банк для вашего финансового успеха',
    desc: 'Наш банк способствует экономическому и социальному развитию резидентов стран СНГ, способствуя их успешному выходу на международные рынки. Мы поддерживаем рост и укрепление бизнеса наших клиентов, предоставляя им для этого широкий спектр высококачественных банковских услуг и внедряя передовые банковские технологии.',
    btnLabel: 'Подробнее',
    btnLink: '/about-bank',
    bgGradient: true,
    textLeft: false,
  }

  return (
    <section className='section'>
      {/* hero banner */}
      <HeroWithBanner {...heroData} />

      {/* quote container */}
      <div className='quote container'>
        <p>
          Международная СберКасса — это способ уверенного продвижения <span>Вашего Бизнеса в сторону успеха</span>.
        </p>
      </div>

      {/* about bank today */}
      <div class='about_bank_today container'>
        {/* top flex section */}
        <div className='about_bank_top'>
          <div className='left'>
            <h3>Банк сегодня</h3>
            <p>
              Международная СберКасса осуществляет операции с Мая 2022 года. Мы обслуживаем индивидуальных и корпоративных
              клиентов различных форм собственности и направлений деятельности.
            </p>
          </div>
          <img src={about_bank_today} alt='About Bank Today' className='right' />
        </div>
        {/* overview points */}
        <div className='overview_points'>
          <div className='line'>
            <p className='number'>01.</p>
            <p className='text'>
              Экономическое развитие Банка основывается на предоставлении клиентам широкого спектра высокотехнологичных банковских
              продуктов и услуг, соответствующих международным стандартам.
            </p>
          </div>
          <div className='line'>
            <p className='number'>02.</p>
            <p className='text'>
              Банк лоялен к каждому русскоязычному клиенту и гарантирует успешность его платежей, независимо от того, в какой
              точке мира он находится.
            </p>
          </div>
          <div className='line'>
            <p className='number'>03.</p>
            <p className='text'>
              Широкая сеть партнерских банков-корреспондентов позволяет осуществлять платежи по всему миру с учетом любых
              пожеланий клиента.
            </p>
          </div>
          <div className='line'>
            <p className='number'>04.</p>
            <p className='text'>
              Вместе с Международной СберКассой вы сможете с уверенностью совершать сделки, инвестировать в недвижимость, а также
              приумножать свой капитал.
            </p>
          </div>
        </div>
        {/* Button */}
        <div className='btn'>
          <RedBtn btnLabel='Подробнее' btnLink='/about-bank' arrow />
        </div>
      </div>

      {/* advantages package */}
      <div className='advantages_package container'>
        {/* header section */}
        <div className='header_section'>
          <div className='left'>
            <h2>Наш пакет преимуществ</h2>
            <p>
              Вы откроете для своего бизнеса новые горизонты с нашим эксклюзивным пакетом финансовых услуг, созданного для вашей
              безопасности и успеха. Выберите нас и вы перенесете ваши финансы в мир стабильности и процветания.
            </p>
          </div>

          {/* for lg devices only */}
          <div className='right'>
            <RedBtn btnLabel='Открыть счет' btnLink='/register' arrow />
          </div>
        </div>

        {/* advantages cards */}
        <div className='advantages_flex'>
          <img src={advantages_cards} alt='advantages package' className='left' />
          {/* grid container */}
          <div className='right'>
            <div className='grid_container'>
              <div className='card bg_gradient'>
                <h3>01. Глобальная платформа</h3>
                <p>
                  Поддерживаем предпринимателей стран СНГ и обеспечиваем их экономическое развитие на международном рынке,
                  обеспечивая им доступ к передовым банковским услугам мирового масштаба.
                </p>
              </div>
              <div className='card'>
                <h3>02. Широкий спектр услуг</h3>
                <p>
                  Предлагаем клиентам разнообразные банковские услуги высокого качества, включая услуги по финансированию,
                  инвестированию, и обслуживанию счетов
                </p>
              </div>
              <div className='card'>
                <h3>03. Инновационные технологии</h3>
                <p>Используем современные банковские технологии, чтобы обеспечить удобство и безопасность для наших клиентов</p>
              </div>
              <div className='card bg_gradient'>
                <h3>04. Безопасность и надежность</h3>
                <p>
                  Осуществляем банковские операции с высоким уровнем безопасности и надежности, защищая интересы наших клиентов
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* for lg devices only */}
        <div className='mobile_btn'>
          <RedBtn btnLabel='Открыть счет' btnLink='/register' arrow />
        </div>
      </div>

      {/* Qoute Container */}
      <div className='quote container'>
        <p>
          Для нас <span>каждый клиент</span> — <span>важный </span>клиент!
        </p>
      </div>

      <div className='container'>
        <NewCustomModal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} popupType={popupType} />

        <div className='home-contact-container'>
          <p className='home-contact-title'>Воспользуйтесь бесплатной консультацией специалиста</p>

          <form className='contact-form' onSubmit={handleSubmit}>
            <div className='input-block'>
              <div className='label'>ФИО</div>
              <MyInput id='name' name='name' value={formData.name} onChange={handleChange} placeholder='Введите ФИО' />
              {errors.name && <p className='home-contact-error'>* {errors.name}</p>}
            </div>
            <div className='input-block'>
              <div className='label'>E-MAIL</div>
              <MyInput id='email' name='email' value={formData.email} onChange={handleChange} placeholder='Введите Email' />
              {errors.email && <p className='home-contact-error'>* {errors.email}</p>}
            </div>
            <div className='input-block'>
              <div className='label'>Телефон</div>
              <MyInput
                id='phone'
                name='phone'
                placeholder='Введите номер телефона'
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <p className='home-contact-error'>* {errors.phone}</p>}
            </div>

            <RedBtn type='submit' btnLabel={isLoading ? 'Отправка...' : 'Отправить'} fullWidth />
          </form>
        </div>
      </div>
    </section>
  )
}

export default Home
