import React from 'react'
import './TariffsAndServices.css'
import NewSlider from '../../components/ui/newSlider/newSlides'
import slide_img_01 from '../../assets/tariffs-and-services/slide-img-01.webp'
import slide_img_02 from '../../assets/tariffs-and-services/slide-img-02.png'
import slide_img_03 from '../../assets/tariffs-and-services/slide-img-03.jpeg'
import slide_img_04 from '../../assets/tariffs-and-services/slide-img-04.jpeg'

function TariffsAndServices() {
  const tableData = [
    { leftContent: 'Комиссия за открытие корпоративного счета: ', rightContent: 'Бесплатно' },
    { leftContent: 'Комиссия за открытие индивидуального счета:', rightContent: 'Бесплатно' },
    {
      leftContent: 'SWIFT, SEPA Корпоративный Расчетный счет:',
      rightContent: (
        <div className='lightText'>
          Сумма минимального депозита: <br />
          10,000 EUR
        </div>
      ),
    },
    {
      leftContent: 'SWIFT, SEPA Корпоративный Пул-счет:',
      rightContent: (
        <div className='lightText'>
          Сумма минимального депозита: <br />
          5,000 EUR
        </div>
      ),
    },
    {
      leftContent: 'SWIFT, SEPA  Персональный счет',
      rightContent: (
        <div className='lightText'>
          Сумма минимального депозита:
          <br /> 5,000 EUR
        </div>
      ),
    },
    {
      leftContent: 'SWIFT, SEPA Индивидуальный Пул-счет:',
      rightContent: (
        <div className='lightText'>
          Сумма минимального депозита:
          <br /> 3,000 EUR
        </div>
      ),
    },

    {
      leftContent: (
        <>
          Отĸрытие Депозитного Счета: <br />
          <div className='lightText'>Минимальная сумма депозита:</div>
        </>
      ),
      rightContent: (
        <>
          500 EUR <br /> <div className='lightText'>10,000 EUR</div>
        </>
      ),
    },
    { leftContent: 'Проверка Комплайнс:', rightContent: 'Бесплатно' },
    {
      leftContent: 'Обслуживание активного счета:',
      rightContent: (
        <>
          30 EUR <div className='lightText'>Ежемесячно</div>{' '}
        </>
      ),
    },

    {
      leftContent: 'Обслуживание интернет-банкинга:',
      rightContent: 'Бесплатно',
    },
    { leftContent: 'Обслуживание интернет-банкинга:', rightContent: 'Бесплатно' },
    {
      leftContent: 'Обслуживание клиента повышенного риска:',
      rightContent: (
        <>
          (Ставку назначает Банк в зависимости <br />
          от уровня риска клиента)
        </>
      ),
    },
    { leftContent: 'Индивидуальное ведение клиента:', rightContent: 'Входит в стоимость открытия счета' },
    {
      leftContent: (
        <>
          Закрытие мультивалютного расчётного счета клиенту, <br />
          не соответствующему Клиентской политике Банка:
        </>
      ),
      rightContent: '100 EUR',
    },
    { leftContent: 'Предварительная проверка документов/комплайнс:', rightContent: 'Бесплатно' },
    { leftContent: 'Внутрибанковские перечисления (все валюты):', rightContent: 'Бесплатно' },
    { leftContent: 'Расследование и ручная обработка входящего платежа:', rightContent: '50 EUR' },
    { leftContent: 'Отзыв или внесение изменений в платёжное распоряжение:', rightContent: '30 EUR' },
    { leftContent: 'Конверсионные операции в валюте: ', rightContent: 'Бесплатно' },
    { leftContent: 'Конверсионные операции в криптовалюте:', rightContent: 'Требуется согласование' },
    {
      leftContent: 'Международный перевод, входящий/исходящий:',
      rightContent: (
        <>
          0.30% <br />
          <div className='lightText'>(Мин. 50 EUR, Макс. 2000 EUR)</div>
        </>
      ),
    },
    { leftContent: 'Выпуск виртуальной карты:', rightContent: '300 EUR' },
    { leftContent: 'Выпуск физической карты:', rightContent: '400 EUR' },
    { leftContent: 'Предоплаченный банковский чек:', rightContent: '100  EUR' },
  ]

  const slidesData = [
    {
      items: [
        { title: '01', text: 'Открытие Корпоративного Счета с персональными реквизитами.', link: '/corporate' },
        { title: '02', text: 'Открытие Общего Корпоративного Счета (с реквизитами банка).', link: '/corporate' },
        { title: '03', text: 'Открытие Индивидуального Счета с персональными реквизитами.', link: '/personal' },
      ],
      image: slide_img_01,
    },
    {
      items: [
        { title: '04', text: 'Открытие Общего Индивидуального Счета (с реквизитами банка).', link: '/personal' },
        { title: '05', text: 'Международные Переводы в более 200 Стран.', link: '/other' },
        { title: '06', text: 'Отĸрытие Корпоративного Счета с персональным SWIFT/ SEPA IBAN', link: '/other' },
      ],
      image: slide_img_02,
    },
    {
      items: [
        { title: '07', text: 'Операции с Криптовалютами.', link: '/other' },
        { title: '08', text: 'Предоплаченные Банковские Чеки.', link: '/other' },
        { title: '09', text: ' Международный Эĸвайринг.', link: '/other' },
      ],
      image: slide_img_03,
    },
    {
      items: [
        { title: '10', text: 'Операции с Драгоценными Металлами.', link: '/other' },
        { title: '11', text: 'Онлайн Банĸинг СберКредит FX', link: '/other' },
      ],
      image: slide_img_04,
    },
  ]

  return (
    <section className='section'>
      <div className='service-wrapper'>
        <div className='service-container container'>
          <div className='corporate-text-content '>
            <div className='corporate-title-block '>
              <div className='corporate-text-content'>
                <div className='service-title'>
                  Услуги на все
                  <br /> случаи жизни
                </div>
              </div>
            </div>

            <div>
              <NewSlider slidesData={slidesData} />
            </div>
            <div className='table-title'>Выгодные Тарифы</div>

            <div class='heading-container'>
              <h1 class='left-heading'>Наименование</h1>
              <h1 class='right-heading'>Сумма</h1>
            </div>

            <div className='static-table'>
              {tableData.map((rowData, index) => (
                <div className='table-row' key={index}>
                  <div className='table-cell cellText'>{rowData.leftContent}</div>
                  <div className='table-cell cellText'>{rowData.rightContent}</div>
                </div>
              ))}
            </div>

            {/* Table End */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TariffsAndServices
