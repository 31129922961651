import React from 'react'
import s from './PaymentCards.module.css'
import HeroWithBanner from '../../components/hero-with-banner/HeroWithBanner'
import heroBanner from '../../assets/payment-cards/hero-banner.webp'
import virtual_card from '../../assets/payment-cards/virtual-card.png'
import physical_card from '../../assets/payment-cards/physical-card.png'

const heroData = {
  heroImage: heroBanner,
  title: 'Дебетовые Карты',
  desc: 'Дебетовые карты (пластиковые, металлические, виртуальные, неименные) — возможность осуществлять безналичные расчеты, снятие наличных, перевод с карты на карту, а также другие операции по запросу через Ваш Онлайн-Банкинг.',
  btnLabel: 'Заказать карту',
  btnLink: '/contact',
  bgGradient: true,
  textLeft: true,
}

function PaymentCards() {
  return (
    <div className={s.payment_cards_page}>
      {/* hero section */}
      <HeroWithBanner {...heroData} />

      {/* bank cards */}
      <div className={`${s.cards} container`}>
        {/* virtual card */}
        <div className={s.card}>
          <div className={s.card_info}>
            <div className={s.info}>
              <h3>Виртуальная карта</h3>
              <div className={s.features}>
                <p>01 Снятие наличных с банковской карты в банкоматах</p>
                <p>02 Перевод денежных средств с карты на карту</p>
                <p>03 Поддержка банковской картой технологии NFC</p>
                <p>04 Осуществление онлайн платежей</p>
              </div>
            </div>
            {/* card img for desktop */}
            <img src={virtual_card} alt='virtual card' className={s.card_img_desktop} />
          </div>
          <div className={s.about_card}>
            <div>
              <p>Платежная система</p>
              <p>MasterCard</p>
            </div>
            <div>
              <p>Валюта</p>
              <p>USD</p>
            </div>
            <div>
              <p>Срок действия</p>
              <p>5 лет</p>
            </div>
            <div>
              <p>Обслуживание</p>
              <p>Бесплатно</p>
            </div>
          </div>
          {/* card img for mobile */}
          <img src={virtual_card} alt='virtual card' className={s.card_img_mobile} />
        </div>

        {/* physical card */}
        <div className={s.card}>
          <div className={s.card_info}>
            <div className={s.info}>
              <h3>Физическая карта</h3>
              <div className={s.features}>
                <p>01 Снятие наличных с банковской карты в банкоматах</p>
                <p>02 Перевод денежных средств с карты на карту</p>
                <p>03 Поддержка банковской картой технологии NFC</p>
                <p>04 Осуществление онлайн платежей</p>
              </div>
            </div>
            {/* card img for desktop */}
            <img src={physical_card} alt='physical card' className={s.card_img_desktop} />
          </div>
          <div className={s.about_card}>
            <div>
              <p>Платежная система</p>
              <p>MasterCard</p>
            </div>
            <div>
              <p>Валюта</p>
              <p>USD</p>
            </div>
            <div>
              <p>Срок действия</p>
              <p>5 лет</p>
            </div>
            <div>
              <p>Обслуживание</p>
              <p>Бесплатно</p>
            </div>
          </div>
          {/* card img for mobile */}
          <img src={physical_card} alt='physical card' className={s.card_img_mobile} />
        </div>
      </div>
    </div>
  )
}

export default PaymentCards
