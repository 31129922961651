import React from 'react'
import s from './OtherServices.module.css'
import RedBtn from '../../components/ui/redBtn/RedBtn'
import SplitCard from '../../components/SplitCard/SplitCard'

import hero_img from '../../assets/other-services/hero-img.jpeg'
import dollar_tree from '../../assets/other-services/dollar-tree.png'
import cash_machine from '../../assets/other-services/cash-machine.jpeg'
import international_acquiring from '../../assets/other-services/international-acquiring.png'
import precious_metals_operations from '../../assets/other-services/precious-metals-operations.jpeg'
import international_sberkassa_FX from '../../assets/other-services/international-sberkassa-fx.png'

// services icons
import cashIcon from '../../assets/other-services/servicesIcons/money-cash-dollar.svg'
import clockIcon from '../../assets/other-services/servicesIcons/time-clock.svg'
import walletIcon from '../../assets/other-services/servicesIcons/wallet.svg'
import percentageIcon from '../../assets/other-services/servicesIcons/percent.svg'

function OtherServices() {
  const heroData = {
    img: hero_img,
    title: 'Депозитный счет',
    description:
      'Депозиты за рубежом — это надежный, простой и законный способ снизить налоговое бремя и сохранить капитал. Депозит за границей нужно рассматривать не только как возможность сохранить и приумножить средства, но и как выгодное вложение.',
    btnLabel: 'Узнать подробнее',
    btnLink: '/contact',
    isHero: true,
  }

  // cashing Bank Checks section data
  const cashingBankChecksData = {
    img: cash_machine,
    title: 'Обналичивание банковских чеков',
    description:
      'Международная СберКасса предлагает услугу обналичивания банĸовсĸих чеĸов. Условия обналичивания: 100 EUR, USD + 2% от суммы самого чека. Процесс обналичивания чеков зависит от Банка-эмитента. Среднее время ожидания варьируется от 7-ми до 14-ти банковских дней.',
    bgGradient: true,
    textFirst: true,
    btnLabel: 'Подробнее',
    btnLink: '/contact',
  }

  // International Acquiring section data
  const internationalAcquiringData = {
    img: international_acquiring,
    title: 'Международный Эквайринг',
    description:
      'Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+ странах мира и выводить заработанные средства на счет компании или ваши персональные реквизиты, а также на криптокошелек. Условия и тарифы уточняются у вашего персонального Менеджера.',
    bgGradient: false,
    textFirst: false,
    btnLabel: 'Подробнее',
    btnLink: '/contact',
    showPaymentMethods: true,
  }

  // International Acquiring section data
  const preciousMetalsOperationsData = {
    img: precious_metals_operations,
    title: 'Операции с Драгоценными Металлами',
    description:
      'Приобретение золотых слитков — это классический способ инвестирования в золото для сохранения и защиты капитала. Ежегодно в мире покупается около 1000 тонн золотых слитков. Исследования показывают, что оптимальный объем инвестиций в золото составляет 2–10% от общей стоимости портфеля, в зависимости от уровня риска. Такой подход позволяет укрепить стабильность портфеля и повысить его доходность в долгосрочной перспективе.',
    bgGradient: true,
    textFirst: true,
    btnLabel: 'Подробнее',
    btnLink: '/contact',
  }

  // International Acquiring section data
  const internationalSberKassaFXData = {
    img: international_sberkassa_FX,
    title: 'Международная СберКасса FX',
    description:
      'Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+ странах мира и выводить заработанные средства на счет компании или на ваш персональный счет, а также на криптокошелек.',
    bgGradient: false,
    textFirst: false,
    btnLabel: 'Подробнее',
    btnLink: '/contact',
  }

  return (
    <div className={s.other_page}>
      {/* hero section */}
      <div id='deposit-account'>
        <SplitCard {...heroData} />
      </div>

      {/* Advantages and benefits */}
      <div className={`${s.benefits} container`}>
        {/* head title */}
        <div className={s.head}>
          <div className={s.left}>
            <h2>Преимущества и выгода</h2>
            <p>
              Откройте новые горизонты финансов с нашим эксклюзивным пакетом преимуществ, который обеспечит вам удобство
              и безопасность. Воспользуйтесь нашими возможностями для достижения уверенности и спокойствия.
            </p>
          </div>
          <div className={s.right}>
            <RedBtn btnLabel='Открыть счет' btnLink='/register' arrow />
          </div>
        </div>
        {/* cards */}
        <div className={s.cards}>
          {/* left img */}
          <img src={dollar_tree} alt='dollar tree' />
          {/* right cards */}
          <div className={s.right}>
            {/* right top */}
            <div className={s.top}>
              <h3>01 Приватность</h3>
              <p>
                Международная СберКасса не разглашает сведения о сумме и движении по счетам клиента государственным структурам
                ни при каких обстоятельствах.
              </p>
            </div>
            {/* right bottom */}
            <div className={s.bottom}>
              <h3>02 Доходность</h3>
              <p>
                Мы предлагаем до 11% годовых новым Клиентам, а также до 12% годовых Клиентам с историей в нашем Банке более двух
                лет.
              </p>
            </div>
          </div>
        </div>
        {/* button for mobile */}
        <div className={s.mobile_btn}>
          <RedBtn btnLabel='Открыть счет' btnLink='/register' arrow />
        </div>
      </div>

      {/* services section */}
      <div className={`${s.services} container`}>
        <h2>Международная CберКасса готова предоставить следующие условия:</h2>
        <div className={s.cards_row1}>
          <div className={s.card}>
            <img src={cashIcon} alt='cash dollar' />
            <h3>Минимальная сумма:</h3>
            <p>10 000 EUR, USD (другие валюты по запросу)</p>
          </div>
          <div className={s.card}>
            <img src={clockIcon} alt='time clock' />
            <h3>Срок размещения:</h3>
            <p>от 3 месяцев до 5 лет</p>
          </div>
          <div className={s.card}>
            <img src={walletIcon} alt='wallet icon' />
            <h3>Выплата процентов:</h3>
            <p>раз в 1, 3, 6, 12 месяцев</p>
          </div>
        </div>
        <div className={s.cards_row2}>
          <div className={s.card}>
            <img src={percentageIcon} alt='percentage icon' />
            <h3>Процент выплаты по депозитам для клиентов с историей более одного года:</h3>
            <p>11% EUR; 12% USD</p>
          </div>
          <div className={s.card}>
            <img src={percentageIcon} alt='percentage icon' />
            <h3>Процент выплаты по депозитам для клиентов без истории:</h3>
            <p>10% EUR; 11% USD</p>
          </div>
        </div>
      </div>

      {/* bank checks */}
      <div id='cashing-checks' className={s.padding_section}>
        <SplitCard {...cashingBankChecksData} />
      </div>

      {/* International Acquiring */}
      <div id='international-acquiring' className={s.padding_section}>
        <SplitCard {...internationalAcquiringData} />
      </div>

      {/* note section */}
      <div className='container'>
        <p className={s.note}>
          Мы успешно справляемся с нестандартными задачами и{' '}
          <span className={s.light_blue}>готовы предложить гарантию лучших условий</span> по сравнению с нашими конкурентами.
        </p>
      </div>

      {/* Precious Metals Operations section */}
      <div id='operations-metals' className={s.padding_section}>
        <SplitCard {...preciousMetalsOperationsData} />
        {/* cards */}
        <div className={`${s.gold_cards} container`}>
          <div className={s.card}>
            <h4>01</h4>
            <p>
              При покупке физического золота с доставкой, Вы можете выбрать предпочтительный вес слитка — 5, 10, 20, 50, 100, 250,
              500 грамм, 1 унцию, или купить стандартные слитки весом 1 килограмм. Комиссия на слитки меньшего веса может
              отличаться от стандартной.
            </p>
            <p>
              Детали и расчеты сделки просим уточнять у Вашего персонального Менеджера, а также через почту
              gold@sberkassa-international.com
            </p>
          </div>
          <div className={s.card}>
            <h4>02</h4>
            <p>
              Международная СберКасса предлагает возможность приобретения золотых слитков напрямую с немецкого аффинажного завода
              C.HAFNER GmbH + Co. KG.
            </p>
          </div>
          <div className={s.card}>
            <h4>03</h4>
            <p>
              Мы предоставляем возможность приобрести золото 999,9 пробы в слитках стандарта LBMA (Лондонская Биржа драгоценных
              металлов) с возможностью хранения в банке — в общем хранилище или в индивидуальной сейфовой ячейке. Вы также можете
              забрать слитки и хранить их самостоятельно. Каждый слиток соответствует отраслевым стандартам.
            </p>
          </div>
        </div>
      </div>

      {/* International SberKassa FX */}
      <div id='sberkassa-fx' className={s.padding_section}>
        <SplitCard {...internationalSberKassaFXData} />
      </div>

      {/* forex trading offers */}
      <div className={`${s.forex} container`}>
        <h3>Мы предлагаем своим Клиентам возможность торговли на рынке Forex в условиях реального времени</h3>
        <div className={s.offers}>
          <div className={s.offer}>
            <p className={s.number}>01.</p>
            <p className={s.line}>Торговля валютными парами, металлами и нефтью</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>02.</p>
            <p className={s.line}>Ликвидность от ведущих банков-маркетмейкеров</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>03.</p>
            <p className={s.line}>Мгновенное исполнение ордеров, благодаря технологиям STP</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>04.</p>
            <p className={s.line}>Узкие спреды и конкурентные котировки от ключевых поставщиков ликвидности</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>05.</p>
            <p className={s.line}>Поддержка стратегий скальпинга</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>06.</p>
            <p className={s.line}>Отсутствие ограничений на установки стоп-ордеров и лимит-ордеров</p>
          </div>
          <div className={s.offer}>
            <p className={s.number}>07.</p>
            <p className={s.line}>
              Удобное открытие счетов, зачисление и вывод средств. После прохождения проверки, операции осуществляются банковским
              платежом на основании соответствующих распоряжений
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtherServices
